import { Button, Col, Form, Input, InputNumber, Modal, Row, Spin, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { formatterNumber, parserNumber } from "../../helpers/input_format";
import { rulesGeneral, toRp } from "../../helpers/general_helper";
import { env } from "../../configs/EnvironmentConfig";
import axios from "axios";
import DetailRequest from "./DetailRequest";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { Upload } from 'antd';

const { Dragger } = Upload;

const RequestPR = (props,{ maxCount,fileList }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [formTwo] = Form.useForm();
  const [isModalVisibleTwo, setIsModalVisibleTwo] = useState(false);
  const [modalNotif, setModalNotif] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formDataTwo, setFormDataTwo] = useState({});

  const onFinish = async () => {
    const values = await formTwo.validateFields();
    setFormDataTwo(values);
    setIsModalVisibleTwo(true);
  }

  const handleModalCancelTwo = () => {
    setIsModalVisibleTwo(false);
  };

  const handleModalCancelNotif = () => {
    setModalNotif(false);
  };

  const [fileListUpload, setFileListUpload] = useState([]);
  const handleFileChange = ({ file, fileList }) => {
    let errorTimeout = 0;

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setTimeout(() => {
        message.error(`Jenis file ${file.name} tidak didukung. Hanya menerima file PNG, JPG, JPEG, dan PDF.`);
      }, errorTimeout);
      errorTimeout += 2000; 
    }
  
    const fileSizeMB = file.size / 1024 / 1024;
    if (fileSizeMB > MAX_FILE_SIZE_MB) {
      setTimeout(() => {
        message.error(`Ukuran file ${file.name} melebihi batas maksimal ${MAX_FILE_SIZE_MB}MB`);
      }, errorTimeout);
      errorTimeout += 3000; 
    }
  
    if (errorTimeout === 0) {
      const updatedFileList = [fileList.slice(-1)[0]]; 
      setFileListUpload(updatedFileList); 
    }
  };

  const MAX_FILE_SIZE_MB = 5; 
  const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

  const handleModalOk = async () => {
    try {
      setLoading(true);
      const values = await formTwo.validateFields();
      setFormDataTwo(values);

      const formData = new FormData();
      formData.append("quotation_number", values.quotation_number);
    Object.keys(values).forEach((key) => {
      if (key === "lampiran") {
        if (fileListUpload.length > 0) {
          formData.append(key, fileListUpload[0].originFileObj);
        }
      }
    });
      Object.keys(values).forEach((key) => {
        if (key !== "quotation_number") {
          formData.append(key, values[key]);
        }
      });

      const response = await fetch(env.API + "/core/purchase-request", {
        method: "POST",
        body: formData,
      });
      setLoading(false);
      const responseData = await response.json();
      if (response.ok) {
        message.success("Data Berhasil Dikirim");
        formTwo.resetFields();
        setIsModalVisibleTwo(false);
        setTotalPrice(null);
        setItems([]);
        setFileListUpload([])
      } else {
        if (response.status === 400 && responseData.code === "BAD_REQUEST") {
         setModalNotif(true)
         setIsModalVisibleTwo(false);
        } else {
          Modal.error({
            title: 'Gagal Dikirim',
            content: 'Terjadi kesalahan saat mengirim data: ' + response.statusText,
          });
        }
      }
    } catch (error) {
      Modal.error({
        title: 'Kesalahan',
        content: 'Terjadi kesalahan: ' + error.message,
      });
    }
  };

  const [quotationNumber, setQuotationNumber] = useState("");
  const [totalPrice, setTotalPrice] = useState(null);
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (quotationNumber !== "") {
      fetchTotalPrice(quotationNumber);
    } else {
      setTotalPrice(null);
      setItems([]);
    }
  }, [quotationNumber]);

  const fetchTotalPrice = async (no_quotation) => {
    try {
      setLoadingQuotation(true)
      const response = await fetch(
        env.API + `/core/price-quotation?no_quotation=${no_quotation}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTotalPrice(data.data.total_price_all);
      setItems(data.data.items);
      setTimeout(() => {
        setLoadingQuotation(false);
      }, 2000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const [preQuotationNumber, setPreQuotationNumber] = useState("");
  const handleQuotationChange = (event) => {
    const input = event.target.value;
    if (input.match(/^\d+\/QUO-KKT\/[A-Z]+\/\d{4}$/)) {
      setQuotationNumber(input);
    } else {
      setQuotationNumber('');
    }
    setPreQuotationNumber(input);
  };


  const columns = [
    {
      title: 'NO. PR',
      dataIndex: 'no_pr',
      key: 'no_pr',
      align: 'center' 
    },
    {
      title: 'Total Harga',
      dataIndex: 'totalPriceOverall',
      key: 'totalPriceOverall',
      render: () => `Rp.${totalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
      align: 'center' 
    },
  ];


  const rulesQuotation = () => [
    {
      pattern: /^\d+\/QUO-KKT\/[A-Z]+\/\d{4}$/,
      message: 'Format nomor penawaran tidak valid. Harap masukkan sesuai format',
    },
    ({ getFieldValue }) => ({
      async validator(_, value) {
        if (!value) {
          return Promise.reject(new Error('Nomor penawaran tidak boleh kosong'));
        }
        if (!/^\d+\/QUO-KKT\/[A-Z]+\/\d{4}$/.test(value)) {
          return Promise.reject(new Error('Nomor penawaran tidak sesuai dengan format yang diharapkan'));
        }
  
        try {
          const response = await fetch(`${env.API}/core/price-quotation?no_quotation=${value}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          
          if (!data.data || !data.data.items) {
            return Promise.reject(new Error('Nomor penawaran tidak ada di sistem'));
          }
        } catch (error) {
          return Promise.reject(new Error('Nomor penawaran Tersebut Tidak Ada'));
        }
  
        return Promise.resolve();
      },
    }),
  ];

  const rulesNomorPermintaan = () => [
    {
      required: true,
      message: 'Tidak Boleh Kosong',
    },
    {
      pattern: /^\d+$/,
      message: 'Nomor permintaan hanya boleh berisi angka!',
    },
  ];

  return (
    <>
      <Form
        layout="vertical"
        name="tambah-pr"
        form={formTwo}
        onFinish={onFinish}
      >
        <div className="">
          <div className="tambah-pr-from-input-wraper-content">
            <div className="header-from-input-wraper-inline">
              <div className="header-from-input-wraper-title">
                <div className="">
                  <h1
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    Tambah No. PR
                  </h1>
                  <p style={{ color: "#6F6C6C", fontSize: "13px" }}>
                    Berikut ini dapat diinputkan data Nomor Pengajuan Pembelian
                    dan Juga Harga kepada koperasi toyota.
                  </p>
                </div>
                <div className="tambah-pr-wraper-border">
                  <div className="tambah-pr-wraper-border-inline">
                    <Row gutter={[16, 16]} justify="start">
                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="No.Permintaan"
                          name="no_pr"
                          rules={rulesNomorPermintaan()}
                        >
                          <Input  
                          placeholder="Masukan No.Permintaan"
                          onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                              e.preventDefault();
                              message.error('Hanya diperbolehkan memasukkan angka!');
                            }
                          }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          hasFeedback
                          label="Header Text"
                          name="header_text"
                          rules={rulesGeneral()}
                        >
                          <Input placeholder="Masukan Header"/>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                       <Form.Item
                        hasFeedback
                        label="No. Penawaran"
                        name="quotation_number"
                        rules={rulesQuotation()}
                      >
                        <Input
                          value={preQuotationNumber}
                          onChange={handleQuotationChange}
                          placeholder="Masukan No.Penawaran"
                        />
                      </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item label="Total Harga">
                          <InputNumber
                            style={{ width: "100%" }}
                            {...props}
                            formatter={(value) => formatterNumber(value)}
                            parser={(value) => parserNumber(value)}
                            value={totalPrice !== null ? totalPrice : ""}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                        hasFeedback
                        label="Lampiran"
                        name="lampiran"
                        rules={rulesGeneral()}
                    >
                      
                       <Dragger
                       fileList={fileListUpload}
                       onChange={handleFileChange}
                       multiple={false}
                       beforeUpload={() => false} 
                       className="hide-remove-icon"
                        style={{ border: '2px solid #e8e8e8', borderRadius: '4px', padding: '20px', textAlign: 'center',background:'white' }}
                        >
                          <p className="ant-upload-drag-icon">
                          <img
                            className=""
                            src="/img/uploadlampiran.png"
                            style={{marginTop:'10px',marginBottom:'10px'}}
                            alt="logo"
                          />
                          </p>
                          <p className="ant-upload-text">
                            Letakan dokumen Anda di sini atau <span style={{color:'#03753F'}}>Pilih Dokumen</span>
                          </p>
                          <p className="ant-upload-hint">
                            Pilih format dokumen dengan format .png, .pdf, .jpg, dan .jpeg
                          </p>
                        </Dragger>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {quotationNumber !== '' && (
              <>
              {loadingQuotation && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24,color:'#03753F' }} spin />} />
                  <p>Loading...</p>
                </div>
              )}
              {!loadingQuotation && (
              <>
              <div className="tambah-pr-wraper-border-detail">
                <div className="tambah-pr-wraper-border-inline-detail">
                  <h1 style={{fontSize:'18px',fontWeight:600,color:'black'}}>Detail Item</h1>
                <Row justify='center'  gutter={[10, 10]}>
                <div style={{padding:'0.1px 0px 0px',backgroundColor:"#DDDFE2BF",width:'100%',marginBottom:'10px'}}></div>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                    <h5 style={{ fontWeight: "600",fontSize:"14px",color:"#BDBDBD",fontWeight:"400" }} >Item Kebutuhan</h5>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                    <h5 style={{ fontWeight: "600",fontSize:"14px",color:"#BDBDBD",fontWeight:"400" }} >Jumlah</h5>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                    <h5 style={{ fontWeight: "600",fontSize:"14px",color:"#BDBDBD",fontWeight:"400" }} >Total Per Unit</h5>
                </Col>
                <div style={{padding:'1px 0px 0px',backgroundColor:"#DDDFE2BF",width:'100%'}}></div>
            </Row>
            {items.map(item => (
              <Row justify='center' gutter={[10, 10]} style={{ marginTop: '20px' }} key={item.id}>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <h5 style={{ fontWeight: "600", fontSize: "14px", color: "#2C2D30", fontWeight: "600" }} >{item.item_permintaan}</h5>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <h5 style={{ fontWeight: "600", fontSize: "14px", color: "#2C2D30", fontWeight: "600" }} >{item.jumlah_permintaan}</h5>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <h5 style={{ fontWeight: "600", fontSize: "14px", color: "#2C2D30", fontWeight: "600" }} >{`Rp.${toRp(item.total_price)}`}</h5>
                </Col>
                <div style={{ padding: '1px 0px 0px', backgroundColor: "#DDDFE2BF", width: '100%' }}></div>
              </Row>
            ))}
            </div>
            </div>
            <div style={{padding:'10px'}}></div>
            </>
             )}
            </>
            )}
            </div>
          </div>
        </div>
        <div className="">
          <div className="footer-input-submit-wraper">
            <div className="footer-input-submit-wraper-button">
              <Row gutter={[16, 16]} justify="space-between" align="top">
                <Col xs={24} sm={12} lg={8}>
                  {/* <button className='button-delete-item-wraper' style={{cursor:'pointer'}}>Hapus Item</button> */}
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item>
                    <button
                      type="primary"
                      htmlType="submit"
                      className="button-send-request-wraper"
                      style={{ cursor: "pointer" }}
                    >
                      KIRIM
                    </button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        title={false}
        visible={isModalVisibleTwo}
        // onOk={handleModalOk}
        // onCancel={handleModalCancelTwo}
        footer={false}
        width={900}
        closable={false}
      >
        <>
                             <div>
                             <h1 className='modal-text-action-wraper' style={{textAlign:'center'}}>Konfirmasi Permintaan Pembelian</h1>
                             <h1 className='modal-text-action-wraper' style={{textAlign:'center',fontSize:'14px',fontWeight:'400',marginBottom:'25px'}}>
                             Setelah anda mengklik tombol kirim pengajuan, maka data pengajuan anda akan secara otomatis terkirim.
                             Jika tidak, anda bisa mengubah pengajuan melalui tombol edit pengajuan.
                              </h1>
                            </div>
                            <Table
                                dataSource={[formDataTwo]}
                                columns={columns}
                                pagination={false}
                            />
                            <div style={{marginLeft:'auto'}}>
                              {/* <h1 style={{fontWeight:'600',fontSize:'16px',marginTop:'10px'}}>Total : {`Rp.${toRp(formData.total_price_all)}`}</h1>  */}
                            </div>
                            <Row gutter={[16, 16]} justify="space-between" align="top">
                            <Col xs={24} sm={12} lg={8}>
                                <div style={{padding: '15px', textAlign: 'center' }}>
                                <button onClick={handleModalCancelTwo} className='button-save-draft-wraper' style={{cursor:'pointer'}}>EDIT</button>
                                </div>
                            </Col>
                            <Col xs={24} sm={12} lg={8}>
                                <div style={{padding: '15px', textAlign: 'center' }}>
                                <button key='confirm' onClick={handleModalOk} disabled={loading} className='button-modal-sendreq-wraper' style={{cursor:'pointer'}}>
                                {loading ? (
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{ fontSize: 24, color: "white" }}
                                        spin
                                      />
                                    }
                                  />
                                ) : (
                                "KIRIM"
                                )}
                                </button>
                                </div>
                            </Col>
                            </Row>
                            </>
                  </Modal>
                <Modal
                  title={false}
                  visible={modalNotif}
                  footer={false}
                  width={500}
                  closable={false}
                >
                  <div style={{textAlign:'center'}}>
                   <img
                    className=""
                    src="/img/errorimage.png"
                    style={{marginTop:'10px',marginBottom:'10px'}}
                    alt="logo"
                  />
                  <h5 style={{ fontWeight: "600",fontSize:"18px",color:'black' }}>Maaf, No. Permintaan tidak bisa ditambahkan</h5>
                  <h5 style={{ fontWeight: "400",fontSize:"14px" }}>No. Penawaran sudah tersubmit sebelumnya</h5>
                  <Button
                    type="primary"
                    style={{ width: "100%",marginTop:'10px',background:'#03753F' }}
                    onClick={handleModalCancelNotif}
                  >
                    TAMBAH NO. PERMINTAAN KEMBALI
                  </Button>
                  </div>
                </Modal>
    </>
  );
};

export default RequestPR;
